import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressError from "../../../components/address-error";
import Card from "../../../components/card";
import Category from "../../../components/category";
import Section from "../../../components/section";
import Shop from "../../../components/shop";
import { setData } from "../../../features/userSlice";
import { getShopCategories } from "../../../services/categories";
import { searchShops } from "../../../services/shops";
import { getUser } from "../../../services/users";
import Dropdown from "../../../components/dropdown";
import SelectDropdown from "../../../components/SelectDropdown"
import Loader from '../../../components/Loader'

function Home() {
    const [loading, setLoading] = useState(true)
    const [loadingShops, setLoadingShops] = useState(true)
    const [shopCategories, setShopCategories] = useState([])
    const [shops, setShops] = useState([])
    const [categoryId, setCategoryId] = useState(null)
    const [dropdownValue, setDropdownValue] = useState({})

    const { addresses } = useSelector(state => state)
    const dispatch = useDispatch()

    const selectCategory = (categoryIdSelected) => {
        if (categoryIdSelected === categoryId) return

        setCategoryId(categoryIdSelected)
        setLoadingShops(true)
        searchShops({ filter: { shopCategory: categoryIdSelected } }).then(res => {
            setShops(res.data.payload)
            setLoadingShops(false)
        })
    }

    useEffect(() => {
        Promise.all([
            // getUser(token),
            getShopCategories(),
        ]).then(([shopCat]) => {
            // dispatch(setData(user.data.payload))
            setShopCategories(shopCat.data.payload)
            setLoading(false)
            setCategoryId(shopCat.data.payload[0].id)
            searchShops({ filter: { shopCategory: shopCat.data.payload[0].id } }).then(res => {
                setShops(res.data.payload)
                setLoadingShops(false)
            })
        })
    }, [dispatch])

    if (loading) return <p>Loading...</p>

    return (
        <Section>
            <div className="flex flex-col w-[300px] mx-auto">
                <SelectDropdown
                    placeholder="Selecciona un departamento"
                    options={shopCategories.map(category => {
                        return {
                            value: category.id,
                            label: category.name
                        }
                    })}
                    onChange={(value) => {
                        selectCategory(value?.value || null)
                        setDropdownValue(value || {})
                    }}
                    value={dropdownValue}
                />
            </div>

            {loadingShops ?
                <Loader />
                :
                shops.length > 0 ?
                    shops.map(shop => <Shop key={shop.id} shop={shop} />)
                    :
                    <p className="text-zinc-500 text-center">No hay comercios para este departamento.</p>
            }
        </Section>

    );
}

export default Home
