import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const addressesSlice = createSlice({
    name: 'addresses',
    initialState,
    reducers: {
        addAddress: (state, { payload }) => {
            state.push(payload)
        },
        deleteAddress: (state, { payload: addressId }) => {
            return state.filter(address => addressId !== address.id)
        },
        updateAddress: (state, { payload: { addressId, name, address } }) => {
            const addressData = state.find((address) => address.id === addressId)
            if (addressData) {
                addressData.name = name
                addressData.address = address
            }
        }
    }

})
export const {
    addAddress,
    deleteAddress,
    updateAddress
} = addressesSlice.actions
export default addressesSlice.reducer