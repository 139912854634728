import { useDispatch, useSelector } from "react-redux"
import { addProductToShopOrder, decrementProductQuantity, incrementProductQuantity, removeProductToShopOrder, toggleSoldOutProduct } from "../../features/shopSlice"
import Input from "../input"
import { RiCheckboxBlankCircleFill, RiMore2Fill } from 'react-icons/ri'
import { useState } from "react"
import Modal from "../Modal"
import EditProductForm from "./EditProductForm"
import toast from "react-hot-toast"
import lang from "../../lang"
import { changeSoldOutStatus } from "../../services/products"
import DeleteProduct from "./DeleteProduct"

import { BsPlusLg } from 'react-icons/bs'
import { AiOutlineMinus } from 'react-icons/ai'

const Product = (props) => {
    const { product: { id, name, description, price, soldOut, image } } = props
    const { token, shop, user } = useSelector(state => state)
    const dispatch = useDispatch()

    console.log(props.product)

    const [productView, setProductView] = useState(false)

    const [productMenuOptions, setProductMenuOptions] = useState(false)
    const [editProduct, setEditProduct] = useState(false)
    const [deleteProduct, setDeleteProduct] = useState(false)

    const selectEditOption = () => {
        setProductMenuOptions(false)
        setEditProduct(true)
    }

    const selectDeleteOption = () => {
        setProductMenuOptions(false)
        setDeleteProduct(true)
    }

    const handleChangeSoldOutStatus = () => {
        changeSoldOutStatus({ token, productId: id, soldOut: !soldOut })
        dispatch(toggleSoldOutProduct({ id }))
    }

    const addToOrder = () => {
        toast.success("Producto agregado al carro.")
        dispatch(addProductToShopOrder({
            quantity: 1,
            product: props.product
        }))
    }

    const incrementQuantity = () => {
        dispatch(incrementProductQuantity({ productId: id }))
    }
    const decrementQuantity = () => {
        if (shop.order[id].quantity === 1) {
            dispatch(removeProductToShopOrder({ productId: id }))
        } else {
            dispatch(decrementProductQuantity({ productId: id }))
        }
    }

    return (
        <>
            <div className="flex justify-between gap-1 px-5 py-1 bg-white border-gray-100 rounded overflow-hidden">
                <div className="flex flex-auto gap-5">
                    <div className="flex items-center justify-center min-h-[150px] h-[150px] min-w-[150px] w-[150px] bg-orange-300 rounded-2xl overflow-hidden">
                        <img src={image} alt="product-img" />
                    </div>
                    <div className="flex flex-col flex-auto justify-center">
                        <p className="flex gap-1 items-center text-zinc-800 text-md font-bold">
                            {name}
                        </p>
                        {description.length > 0 && <p className="text-zinc-600 text-sm">{description}</p>}
                        <div className="flex flex-col gap-2">
                            <p className="text-zinc-500"><span className="text-zinc-500">L.</span> {price}</p>

                            {shop.order.hasOwnProperty(id) &&
                                <div className="flex bg-gray-100 rounded-full w-[125px]">
                                    <button
                                        className="min-w-[35px] min-h-[35px] flex items-center justify-center rounded-full bg-orange-300 text-white"
                                        onClick={decrementQuantity}
                                    >
                                        <AiOutlineMinus />
                                    </button>
                                    <input className="bg-transparent w-full outline-none text-center text-zinc-500" value={shop.order[id].quantity} disabled />
                                    <button
                                        className="min-w-[35px] min-h-[35px] flex items-center justify-center rounded-full bg-orange-300 text-white"
                                        onClick={incrementQuantity}
                                    >
                                        <BsPlusLg />
                                    </button>
                                </div>
                            }
                            {!shop.order.hasOwnProperty(id) && <button className="flex items-center justify-center bg-orange-300 text-white w-[125px] h-[35px] rounded-full" onClick={addToOrder}>Agregar</button>}
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Product