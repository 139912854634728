import { useEffect } from "react"
import { Outlet } from "react-router-dom"

const NoAuthPages = () => {
    useEffect(() => {
        document.body.style.overflow = 'auto'
    }, [])
    return <Outlet />
}

export default NoAuthPages