import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Card from "../../../components/card"
import Section from "../../../components/section"
import Shop from "../../../components/shop"
import { setShopList } from "../../../features/shopsSlice"
import { setData } from "../../../features/userSlice"
import { getShops } from "../../../services/shops"
import { getUser } from "../../../services/users"

const MyShops = () => {
    const token = useSelector(state => state.token)
    const shops = useSelector(state => state.shops)
    const dispatch = useDispatch()

    useEffect(() => {
        getUser(token).then(user => dispatch(setData(user.data.payload)))
        getShops(token).then(shops => dispatch(setShopList(shops.data.payload)))
    }, [token, dispatch])

    return (
        <Section>
            <Card>
                {
                    shops.length > 0 ?
                        shops.map(shop => <Shop key={shop.id} shop={shop} showOptions={true} />)
                        :
                        <p>No tenes tiendas creadas.</p>
                }
            </Card>
        </Section>
    )
}

export default MyShops