import { useEffect, useState } from "react"
import Card from "../../../../components/card"
import Product from "../../../../components/product"
import { RiSearchLine } from "react-icons/ri"
import Input from "../../../../components/input"
import { searchProducts } from "../../../../services/products"
import { useParams } from "react-router-dom"
import toast from "react-hot-toast"
import Button from "../../../../components/button"

const Search = () => {
    const { id } = useParams()
    const [text, setText] = useState("")
    const [products, setProducts] = useState([])
    const [searching, setSearching] = useState()

    const handleSearch = (event) => {
        event.preventDefault()
        if (text.length === 0) return
        setSearching(true)
        searchProducts({ shopId: id, text }).then((res) => {
            setSearching(false)
            setProducts(res.data.payload.products)
        })
            .catch(() => {
                setSearching(false)
                toast.error('Ha ocurrido un error')
            })
    }

    return (
        <section className="flex flex-col gap-5 pb-16">
            <form className="flex px-5 gap-2 items-center" onSubmit={handleSearch}>
                <Input className="w-full" type="text" placeholder="Buscar" onChange={({ target: { value } }) => setText(value)} value={text} />
                <Button spinner={searching} disabled={searching}>{!searching && <RiSearchLine />}Buscar</Button>
            </form>
            {
                products.length > 0 ?
                    <>
                        <p className="text-zinc-500 px-5">Productos encontrados {products.length}</p>
                        {products.map(product => (
                            <Product key={product.id} product={product} />
                        ))}
                    </>
                    : <p className="text-zinc-500 text-center">No se encontraron productos</p>
            }
        </section>
    )
}

export default Search