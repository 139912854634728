import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Order from "../../../components/order"
import Section from "../../../components/section"
import { setData } from "../../../features/userSlice"
import { getUser, getUserOrders } from "../../../services/users"

const Orders = () => {
    const { customer } = useSelector(state => state)
    const dispatch = useDispatch()

    const [orders, setOrders] = useState([])

    useEffect(() => {
        getUserOrders(customer.token).then((res) => {
            setOrders(res.data.payload)
        })
    }, [dispatch])

    return (
        <section className="flex flex-col gap-5 max-w-[300px] w-full mx-auto">
            {
                orders.length > 0 ?
                    orders.map((order) => <Order key={order.id} order={order} />)
                    :
                    <p className="text-zinc-500 text-center">No tienes ordenes de compra.</p>
            }
        </section>
    )
}

export default Orders