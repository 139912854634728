import { useEffect, useState } from "react"
import { getPendingOrders } from "../../../../services/shops"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { RiCheckboxBlankCircleFill } from "react-icons/ri"
import { HiLocationMarker } from "react-icons/hi"
import Modal from "../../../../components/Modal"
import PendingOrder from "./PendingOrder"
import { setShopOrders } from "../../../../features/shopOrdersSlice"


const ShopOrders = () => {
    const { id: shopId } = useParams()
    const { token, shopOrders } = useSelector(state => state)
    const [pendingOrders, setPendingOrders] = useState([])
    const [orderMenu, setOrderMenu] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        getPendingOrders({ token, shopId }).then((res) => {
            setPendingOrders(res.data.payload.orders)
            dispatch(setShopOrders(res.data.payload.orders))
        })
    }, [])

    return (
        <div className="p-2">

            <div className="flex flex-col gap-5">
                <p className="text-center text-xs uppercase font-semibold">Ordenes de compra pendientes</p>
                {shopOrders.length > 0 && <p className="text-center text-gray-700 text-xs uppercase">Doble click en los elementos para ver detalles.</p>}
                {shopOrders.length > 0 ?
                    shopOrders.map((order, index) => <PendingOrder key={order.id} order={order} />)
                    :
                    <p className="uppercase text-xs text-center text-gray-500">No hay ordenes pendientes.</p>
                }
            </div>
        </div>

    )
}

export default ShopOrders