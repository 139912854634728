import axios from "axios"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../../components/button"
import Card from "../../../components/card"
import Category from "../../../components/category"
import Input from "../../../components/input"
import Section from "../../../components/section"
import { API_URL } from "../../../config"
import { setData } from "../../../features/userSlice"
import { getShopCategories, getProductCategories } from '../../../services/categories'
import { getUser } from "../../../services/users"

const Categories = () => {

    const token = useSelector(state => state.token)
    const dispatch = useDispatch()

    const [shopCategories, setShopCategories] = useState([])
    const [productCategories, setProductCategories] = useState([])
    const [shopCategoryName, setShopCategoryName] = useState({
        name: ''
    })
    const [productCategoryName, setProductCategoryName] = useState({
        name: ''
    })

    const handleChangeShop = ({ target }) => setShopCategoryName({ ...shopCategoryName, [target.name]: target.value })
    const handleChangeProduct = ({ target }) => setProductCategoryName({ ...productCategoryName, [target.name]: target.value })

    const onSubmitShop = (event) => {
        event.preventDefault()
        axios.post(`${API_URL}/shops/categories`, shopCategoryName, {
            headers: {
                authorization: 'Bearer ' + token
            }
        }).then(res => {
            toast.success(res.data.message)
            setShopCategories([...shopCategories, res.data.payload])
        }).catch(({ response: res }) => toast.error(res.data.message))
    }

    const onSubmitProduct = (event) => {
        event.preventDefault()
        axios.post(`${API_URL}/products/categories/`, productCategoryName, {
            headers: {
                authorization: 'Bearer ' + token
            }
        }).then(res => {
            toast.success(res.data.message)
            setProductCategories([...productCategories, res.data.payload])
        }).catch(({ response: res }) => toast.error(res.data.message))
    }

    useEffect(() => {
        Promise.all([
            getShopCategories(),
            getProductCategories(),
            getUser(token)
        ]).then(([shopCat, productCat, user]) => {
            setShopCategories(shopCat.data.payload)
            setProductCategories(productCat.data.payload)
            dispatch(setData(user.data.payload))
        })

    }, [dispatch, token])

    return (
        <Section>
            <Card>
                <h1 className="text-lg text-zinc-600">Categorías de comercios</h1>
                <form className="flex flex-col gap-5" onSubmit={onSubmitShop}>
                    <div className="flex flex-col gap-2">
                        <label>Nombre <span className="text-xs">*Obligatorio</span></label>
                        <Input type="text" name="name" placeholder="Kiosco" value={shopCategoryName.name} onChange={handleChangeShop} />
                    </div>
                    <Button>Crear</Button>
                </form>
            </Card>

            <Card>
                <h1 className="text-lg text-zinc-600">Categorías de productos</h1>
                <form className="flex flex-col gap-5" onSubmit={onSubmitProduct}>
                    <div className="flex flex-col gap-2">
                        <label>Nombre <span className="text-xs">*Obligatorio</span></label>
                        <Input type="text" name="name" placeholder="Bebidas" value={productCategoryName.name} onChange={handleChangeProduct} />
                    </div>
                    <Button>Crear</Button>
                </form>
            </Card>

            <Card>
                <h1 className="text-lg text-zinc-600">Lista</h1>
                <h1 className="uppercase text-zinc-600 font-semibold">Comercios</h1>
                {shopCategories.length > 0 ?
                    shopCategories.map(category => (
                        <Category key={category.id} category={category} showDate />
                    ))
                    :
                    <p className="text-zinc-600">No hay categorías de comercios creadas.</p>
                }
                <h1 className="uppercase text-zinc-600 font-semibold">Productos</h1>
                {productCategories.length > 0 ?
                    productCategories.map(category => (
                        <Category key={category.id} category={category} showDate />
                    ))
                    :
                    <p className="text-zinc-600">No hay categorías de comercios creadas.</p>
                }
            </Card>
        </Section>
    )
}

export default Categories