import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    numberPhone: null,
    addresses: [],
    isAdmin: false,
    deliveryman: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setData: (state, { payload }) => {
            state.id = payload.id
            state.firstName = payload.firstName
            state.lastName = payload.lastName
            state.email = payload.email
            state.numberPhone = payload.numberPhone
            state.addresses = payload.addresses
            state.isAdmin = payload.isAdmin
            state.deliveryman = payload.deliveryman
        },
        addAddress: (state, { payload }) => {
            state.addresses.push(payload)
        },
        deleteAddress: (state, { payload }) => {
            const newAddr = state.addresses.filter(item => item.id !== parseInt(payload))
            state.addresses = newAddr
        },
        initUser: () => initialState
    }

})
export const { setData, setToken, addAddress, deleteAddress, initUser } = userSlice.actions
export default userSlice.reducer