import axios from "axios";
import { API_URL } from "../config";

export const getUserShop = (token, shopId) => axios.get(`${API_URL}/shops/own/${shopId}`, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const getShop = (token, shopId) => axios.get(`${API_URL}/shops/${shopId}`, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const getShops = (token) => axios.get(`${API_URL}/shops`, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const getShopSections = ({ token, shopId }) => axios.get(`${API_URL}/shops/${shopId}/sections`, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const getShopProducts = ({ token, shopId }) => axios.get(`${API_URL}/shops/${shopId}/products`, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const getPendingOrders = ({ token, shopId }) => axios.get(`${API_URL}/shops/${shopId}/orders/pending`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const createShop = (token, data) => axios.post(`${API_URL}/shops`, data, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const updateShop = (token, shopId, data) => axios.put(`${API_URL}/shops/${shopId}`, data, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const deleteShop = (token, shopId) => axios.delete(`${API_URL}/shops/${shopId}`, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const updateShopStatus = (token, shopId) => axios.patch(`${API_URL}/shops/${shopId}/status`, {}, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const searchShops = ({ filter: { shopCategory } }) => axios.get(`${API_URL}/shops/${shopCategory}/search`)