import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import Card from "../../../../components/card"
import Dropdown from "../../../../components/dropdown"
import FormCreateProduct from "../../../../components/form-create-product"
import FormCreateSection from "../../../../components/form-create-section"
import ShopSection from "../../../../components/shop-section"
import { setSections, setShopData } from "../../../../features/shopSlice"
import { setData } from "../../../../features/userSlice"
import { getShop, getShopSections } from "../../../../services/shops"
import { getUser } from "../../../../services/users"
import Loader from "../../../../components/Loader"

const ShopSections = () => {
    const { id: shopId } = useParams()
    const [loading, setLoading] = useState(true)

    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const shop = useSelector(state => state.shop)

    const dispatch = useDispatch()

    useEffect(() => {
        Promise.all([
            getShop(token, shopId),
            getShopSections({ token, shopId }),
        ]).then(([shop, sections]) => {
            dispatch(setShopData(shop.data.payload))
            dispatch(setSections(sections.data.payload))
            setLoading(false)
        })
    }, [dispatch, token, shopId])

    if (loading) {
        return (<div className="flex justify-center items-center h-[400px]">
            <Loader />
        </div>)
    }

    return (
        <section className="flex flex-col gap-5 p-5">
            {
                shop.shopSections.length > 0 ?
                    <div className="grid grid-cols-2 gap-5">
                        {shop.shopSections.map(section => (
                            <ShopSection key={section.id} section={section} initialState={true} />
                        ))}
                    </div>
                    : <p className="text-zinc-500">No hay categorías creadas.</p>
            }
        </section>
    )
}

export default ShopSections