import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom"
import OrderList from "../../../components/order-list"
import { setShopData } from "../../../features/shopSlice"
import { getShop } from "../../../services/shops"
import { MdArrowBackIosNew } from 'react-icons/md'
import { RiShoppingCart2Line } from "react-icons/ri"
import Loader from "../../../components/Loader"

const Shops = () => {
    const { id } = useParams()
    const token = useSelector(state => state.token)
    const shop = useSelector(state => state.shop)

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        Promise.all([
            getShop(token, id)
        ]).then(([shop]) => {
            dispatch(setShopData(shop.data.payload))
            setLoading(false)
        })
    }, [dispatch, id, token])

    if (loading) {
        return (
            <div className="flex justify-center items-center h-[400px]">
                <Loader />
            </div>
        )
    }

    return (
        <section className="flex flex-col gap-5 bg-[#FFAF00]">
            <div className="flex items-center justify-between p-5">
                <button className="text-white text-xl" onClick={() => navigate('/home')}><MdArrowBackIosNew /></button>
                <p className="text-white">{shop.name}</p>
                <button className="flex gap-1 items-center bg-[#ffffff59] p-1 rounded-lg text-white" onClick={() => setOpen(true)}>
                    <RiShoppingCart2Line className="text-xl" />
                    {Object.values(shop.order).length}
                </button>
            </div>
            {
                Object.values(shop.order).length > 0 &&
                <OrderList />
            }
            <div className="flex flex-col rounded-t-3xl bg-white py-5 gap-5">
                <section className="flex gap-5 justify-center">
                    <NavLink to={`/shops/${id}`} className={({ isActive }) => `text-center ${isActive ? 'bg-orange-50 text-orange-300' : 'text-zinc-400'} px-3 py-2 rounded-full`} end>Categorías</NavLink>
                    <NavLink to={`/shops/${id}/products`} className={({ isActive }) => `text-center ${isActive ? 'bg-orange-50 text-orange-300' : 'text-zinc-400'} px-3 py-2 rounded-full`} end>Productos</NavLink>
                    <NavLink to={`/shops/${id}/search`} className={({ isActive }) => `text-center ${isActive ? 'bg-orange-50 text-orange-300' : 'text-zinc-400'} px-3 py-2 rounded-full`} end>Buscar</NavLink>
                </section>
                <Outlet />
            </div>
        </section>
    )
}

export default Shops