import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Card from "../../../components/card"
import Delivery from "../../../components/delivery"
import Section from "../../../components/section"
import { setData } from "../../../features/userSlice"
import { getDeliveries } from "../../../services/deliveries"
import { getUser } from "../../../services/users"

const MyDeliveries = () => {
    const token = useSelector(state => state.token)
    const [loading, setLoading] = useState(true)
    const [deliveries, setDeliveries] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        getUser(token).then(user => dispatch(setData(user.data.payload)))
        getDeliveries({ token }).then(deliveries => {
            setDeliveries(deliveries.data.payload)
            setLoading(false)
        })
    }, [token, dispatch])

    if (loading) return <p>Loading</p>

    return (
        <Section>
            {deliveries.length > 0 ?
                deliveries.map((delivery) => <Delivery key={delivery.id} delivery={delivery} showCancelButton={delivery.status > 0 && delivery.status < 3} showChangeStatusButton={delivery.status > 0 && delivery.status < 6} />)
                :
                <Card>No tenes pedidos aceptados.</Card>
            }
        </Section>
    )
}

export default MyDeliveries
