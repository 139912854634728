import { useEffect, useRef, useState } from "react"
import { RiArrowDownSLine, RiArrowUpSLine, RiCheckLine } from "react-icons/ri"

const SelectDropdown = ({ initialState = false, placeholder = '', value = {}, options = [], onChange = () => { } }) => {
    const content = useRef()
    const [open, setOpen] = useState(true)
    const [contentHeight, setContentHeight] = useState(initialState)
    const [currentValue, setCurrentValue] = useState(value)

    const handleSelectOption = (option) => {
        if (option.label === currentValue?.label && option.value === currentValue?.value) {
            setCurrentValue({})
            setOpen(false)
            onChange(null)
            return
        }
        setCurrentValue(option)
        onChange(option)
        setOpen(false)
    }

    useEffect(() => {
        if (content.current) {
            setContentHeight(content.current.clientHeight)
            setOpen(initialState)
        }
    }, [])

    return (
        <div className={`flex flex-col ${open ? 'gap-2' : 'gap-0'} duration-75`}>
            <button className="flex-auto flex h-[50px] px-5 justify-between items-center gap-2 text-orange-300 bg-orange-50 rounded-full" onClick={() => setOpen(!open)}>
                {currentValue?.label?.length > 0 ? currentValue.label : placeholder}
                {open ? <RiArrowUpSLine className="text-2xl" /> : <RiArrowDownSLine className="text-2xl" />}
            </button>
            <div
                ref={content}
                style={{ height: open ? contentHeight : 0 }}
                className={`flex flex-col max-h-[250px] overflow-auto rounded bg-gray-100 w-full ${open ? 'visible' : 'invisible'} shadow-sm duration-75 invisible-scroll`}
            >
                {options.map((option, index) => (
                    <button
                        key={index}
                        className="flex justify-between items-center bg-orange-50 hover:bg-gray-100 px-5 py-3 text-orange-300 text-left outline-none"
                        onClick={() => handleSelectOption(option)}
                    >

                        {option.label || option.value}
                        {currentValue?.value === option.value && <RiCheckLine className="text-2xl text-orange-500" />}
                    </button>
                ))}
            </div>
        </div>

    )
}

export default SelectDropdown