import { RiCheckboxBlankCircleFill, RiShoppingCart2Line } from "react-icons/ri"
import Card from "../card"
import Modal from "../Modal"
import lang from "../../lang"
import { useState } from "react"
import { AiFillCreditCard } from "react-icons/ai"
import { BsCashStack, BsTrash } from "react-icons/bs"
import Input from "../input"
import Button from "../button"
import { MdArrowBackIosNew } from "react-icons/md"

/*
Estados delivery
[0] Esperando repartidor
[1] Repartidor encontrado
[2] Repartidor en el local
[3] Repartidor en camino
[4] El repartidor ha llegado
[5] Entregado
[6] Cancelado
*/

const states = {
    0: 'Esperando repartidor',
    1: 'Repartidor encontrado',
    2: 'Repartidor en el local',
    3: 'Repartidor en camino',
    4: 'Repartidor ha llegado',
    5: 'Entregado',
    6: 'Cancelado',
}

const Order = (
    {
        order: {
            id,
            shop: { name },
            order_products,
            address,
            amount,
            shippingPrice,
            ready,
            deliveryInProgress,
            delivered,
            paymentMethod,
            paid,
            createdAt
        }
    }
) => {
    const [orderMenu, setOrderMenu] = useState()
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', };
    return (
        <>
            <Modal modalOpen={orderMenu} set={setOrderMenu}>
                <div className="bg-[#FFAF00] flex flex-col gap-5 w-full h-full overflow-auto rounded">
                    <div className="flex items-center justify-between p-5">
                        <button className="text-white text-xl" onClick={() => setOrderMenu(false)}><MdArrowBackIosNew /></button>
                        <p className="text-white">Detalles</p>
                        <button></button>
                    </div>
                    <div className="flex flex-col gap-5 bg-white rounded-t-3xl min-h-full max-h-full overflow-auto p-5">
                        {order_products.map(item => (
                            <div key={item.product.id} className="flex justify-between gap-1 py-1 bg-white border-gray-100 rounded">
                                <div className="flex flex-auto items-center gap-5">
                                    <div className="flex items-center justify-center min-h-[70px] max-h-[70px] min-w-[70px] max-w-[70px] bg-orange-300 rounded overflow-hidden">
                                        <img className="w-full" src={item.product.image} alt="product-img" />
                                    </div>
                                    <div className="flex flex-col flex-auto justify-center">
                                        <p className="flex gap-1 items-center text-zinc-800 text-md font-bold">
                                            {item.product.name}
                                        </p>
                                        {item.product.description.length > 0 && <p className="text-zinc-600 text-sm">{item.product.description}</p>}
                                        <div className="flex flex-col gap-2">
                                            <p className="text-zinc-500"><span className="text-zinc-500">L.</span> {item.product.price}</p>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        ))}
                        <div className="flex justify-between">
                            <p className="text-orange-400 text-center text-sm uppercase">{lang['es']['quantity']}</p>
                            <p className="text-orange-400 text-center text-sm uppercase">{order_products.reduce((acc, crr) => Number(acc) + Number(crr.quantity), 0)}</p>
                        </div>

                        <div className="flex justify-between">
                            <p className="text-orange-400 text-center text-sm uppercase">{lang['es']['payment_method']}</p>
                            <p className="text-orange-400 text-center text-sm uppercase">{paymentMethod === 'CREDIT_CARD' ? lang['es']['credit_card'] : lang['es']['cash']}</p>
                        </div>

                        <div className="flex justify-between">
                            <p className="text-orange-400 text-center text-sm uppercase">{lang['es']['payment_state']}</p>
                            <p className="text-orange-400 text-center text-sm uppercase">{paid ? lang['es']['paid'] : lang['es']['not_paid']}</p>
                        </div>

                        <div className="flex justify-between">
                            <p className="text-orange-400 text-center text-sm uppercase">{lang['es']['total']}</p>
                            <p className="text-orange-400 text-center text-sm uppercase">{amount} {lang['currency']['abbreviation']}</p>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="bg-orange-50 p-5 rounded-lg" onClick={() => setOrderMenu(true)}>
                <div className="flex gap-5">
                    <div className="flex flex-col">
                        <p className="text-md text-orange-400 font-semibold">{name}</p>
                        <div className="flex gap-1 items-center">
                            <p className="text-xs text-orange-400">{amount} {lang['currency']['abbreviation']}</p>
                            <p className="text-xs text-orange-400"><RiCheckboxBlankCircleFill className="text-[5px]" /></p>
                            <p className="text-xs text-orange-400">{order_products.length} {order_products.length > 1 ? 'Productos' : 'Producto'}</p>
                        </div>
                        <div className="flex flex-col">
                            {/* <div className="top-0 left-[3.5px] border-l-2 border-gray-200 py-1 h-full w-[1px]"></div> */}
                            {!ready &&
                                <div className="border-l-2 border-gray-200 py-1">
                                    <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                        <RiCheckboxBlankCircleFill className="text-[8px] text-orange-200" /> Esperando confirmación del comercio
                                    </p>
                                </div>
                            }

                            {ready &&
                                <div className="border-l-2 border-gray-200 py-1">
                                    <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                        <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> El comercio ha preparado tu pedido
                                    </p>
                                </div>
                            }

                            {ready && !deliveryInProgress &&
                                <div className="border-l-2 border-gray-200 py-1">
                                    <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                        <RiCheckboxBlankCircleFill className="text-[8px] text-orange-200" /> Esperando repartidor
                                    </p>
                                </div>
                            }

                            {ready && deliveryInProgress &&
                                <div className="border-l-2 border-gray-200 py-1">
                                    <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                        <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> Repartidor asignado
                                    </p>
                                </div>
                            }


                            {ready && deliveryInProgress && !delivered &&
                                <div className="border-l-2 border-blue-200 py-1">
                                    <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                        <RiCheckboxBlankCircleFill className="text-[8px] text-blue-500" /> Repartidor en camino
                                    </p>
                                </div>
                            }

                            {/* {ready && deliveryInProgress && delivered &&
                            <div className="border-l-2 border-gray-200 py-1">
                                <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                    <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> El repartidor ha llegado
                                </p>
                            </div>
                        } */}


                            {ready && deliveryInProgress && delivered &&
                                <div className="border-l-2 border-gray-200 py-1">
                                    <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                        <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> Orden entregada
                                    </p>
                                </div>
                            }
                        </div>
                        <p className="flex items-center gap-1 text-xs text-orange-500">{new Date(createdAt).toLocaleDateString("es-HN", options)}. {new Date(createdAt).getHours()}:{new Date(createdAt).getMinutes()}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Order