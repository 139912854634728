import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Card from "../../../components/card"
import Delivery from "../../../components/delivery"
import Section from "../../../components/section"
import { setData } from "../../../features/userSlice"
import { getPendingDeliveries } from "../../../services/deliveries"
import { getUser } from "../../../services/users"

const Deliveries = () => {
    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const dispacth = useDispatch()
    const [deliveries, setDeliveries] = useState([])
    useEffect(() => {
        getUser(token).then(user => dispacth(setData(user.data.payload)))
        getPendingDeliveries({ token }).then(deliveries => {
            setDeliveries(deliveries.data.payload)
        })
    }, [dispacth, token])
    return (
        <Section>
            {user.deliveryman ?
                <>
                    {deliveries.length > 0 ?
                        deliveries.map(delivery => <Delivery key={delivery.id} delivery={delivery} />)
                        :
                        <Card>No hay repartos pendientes.</Card>    
                    }
                </> :

                <Card>
                    <p className="text-zinc-600">Tu perfil de repartidor no está verificado.</p>
                </Card>
            }
        </Section>
    )
}

export default Deliveries
