import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    fullName: '',
    phoneNumber: '',
    token: '',
    orders: []
}

export const shopOrdersSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomer: (state, { payload }) => payload,
        addCustomerOrder: (state, { payload }) => {
            state.orders.push(payload)
        },
        resetCustomer: () => initialState,
    },

})
export const {
    setCustomer,
    addCustomerOrder,
    resetCustomer
} = shopOrdersSlice.actions
export default shopOrdersSlice.reducer