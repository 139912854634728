import axios from "axios";
import { API_URL } from "../config";

export const getDeliveries = ({ token }) => axios.get(`${API_URL}/deliveries`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const getPendingDeliveries = ({ token }) => axios.get(`${API_URL}/deliveries/pending`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const acceptDelivery = ({ token, deliveryId }) => axios.post(`${API_URL}/deliveries/${deliveryId}/accept`, {}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const incrementStatus = ({ token, deliveryId }) => axios.patch(`${API_URL}/deliveries/${deliveryId}/status/increment`, {}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export const decrementStatus = ({ token, deliveryId }) => axios.patch(`${API_URL}/deliveries/${deliveryId}/status/decrement`, {}, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
export const cancelDelivery = ({ token, deliveryId, cancellationReason }) => axios.patch(`${API_URL}/deliveries/${deliveryId}/cancel`, { cancellationReason }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})