import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { AiFillCreditCard, AiOutlineMinus } from 'react-icons/ai'
import { BsCashStack, BsPlusLg, BsTrash } from 'react-icons/bs'

import {
    decrementProductQuantity,
    incrementProductQuantity,
    removeProductToShopOrder,
    resetOrder
} from "../../features/shopSlice"

import { createOrder } from "../../services/orders"
import lang from "../../lang"
import Input from "../input"
import Modal from "../Modal"
import { RiShoppingCart2Line } from "react-icons/ri"
import Button from "../button"
import { MdArrowBackIosNew } from 'react-icons/md'

const OrderList = ({ open = false }) => {
    const { id: shopId } = useParams()
    const [address, setAddress] = useState('')
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [creditCard, setCreditCard] = useState({
        titularName: '',
        creditCardNumber: '',
        cvc: '',
        expiration: ''
    })

    const navigate = useNavigate()

    const [requesting, setRequesting] = useState()

    const [cartOpen, setCartOpen] = useState(open)

    const { shop, customer } = useSelector(state => state)

    const dispatch = useDispatch()

    const selectAddress = (addr) => {
        setAddress(addr.id !== address?.id ? addr : null)
    }

    const sendOrder = () => {
        setRequesting(true)
        const order = Object.values(shop.order).map(item => {
            return {
                quantity: item.quantity,
                productId: item.product.id
            }
        })
        createOrder({ token: customer.token, products: order, address, shopId, paymentMethod })
            .then((res) => {
                dispatch(resetOrder())
                toast.success(res.data.message)
                setRequesting(false)
                navigate('/orders')
            })
            .catch(({ response: res }) => {
                toast.error(res.data.message)
                setRequesting(false)
            })
    }

    const incrementQuantity = (productId) => {
        dispatch(incrementProductQuantity({ productId }))
    }
    const decrementQuantity = (productId) => {
        if (shop.order[productId].quantity === 1) {
            dispatch(removeProductToShopOrder({ productId }))
        } else {
            dispatch(decrementProductQuantity({ productId }))
        }
    }
    const removeProduct = (productId) => {
        dispatch(removeProductToShopOrder({ productId }))
    }

    const handleChangeAddress = ({ target: { value } }) => setAddress(value)

    const handleChangeCreditData = ({ target: { name, value } }) => setCreditCard({ ...creditCard, [name]: value })

    const handleCreditCardName = ({ target: { name, value } }) => {
        if (value.length === 0) {
            setCreditCard({ ...creditCard, [name]: '' })
            return
        }
        if (!/^[a-zA-Z\u00C0-\u017F\s]+$/.test(value)) return
        setCreditCard({ ...creditCard, [name]: value })
    }

    const handleCvcCreditCard = ({ target: { name, value } }) => {
        if (value.length > 3) {
            return
        }

        setCreditCard({ ...creditCard, [name]: value })
    }

    const validateCreditCard = () => {
        if (paymentMethod !== 'CREDIT_CARD') return true

        if (creditCard.titularName.length === 0 || creditCard.creditCardNumber.length === 0 || creditCard.cvc.length === 0 || creditCard.expiration.length === 0) return false
        if (creditCard.titularName.length > 0 && creditCard.creditCardNumber.length > 0 && creditCard.cvc.length > 0 && creditCard.expiration.length > 0) return true
    }
    
    return (
        <>
            <Modal modalOpen={cartOpen}>
                <div className="bg-[#FFAF00] flex flex-col gap-5 w-full h-full rounded overflow-auto">
                    <div className="flex items-center justify-between p-5">
                        <button className="text-white text-xl" onClick={() => setCartOpen(false)}><MdArrowBackIosNew /></button>
                        <p className="text-white">Carrito</p>
                        <button className="flex gap-1 items-center bg-[#ffffff59] p-1 rounded-lg text-white">
                            <RiShoppingCart2Line className="text-xl" />
                            {Object.values(shop.order).length}
                        </button>
                    </div>
                    <div className="flex flex-col gap-5 bg-white rounded-t-3xl min-h-full max-h-full overflow-auto p-5">
                        {Object.values(shop.order).map(item => (
                            <div key={item.product.id} className="flex justify-between gap-1 py-1 bg-white border-gray-100 rounded">
                                <div className="flex flex-auto items-center gap-5">
                                    <div className="flex items-center justify-center min-h-[70px] max-h-[70px] min-w-[70px] max-w-[70px] bg-orange-300 rounded overflow-hidden">
                                        <img className="w-full" src={item.product.image} alt="product-img" />
                                    </div>
                                    <div className="flex flex-col flex-auto justify-center">
                                        <p className="flex gap-1 items-center text-zinc-800 text-md font-bold">
                                            {item.product.name}
                                        </p>
                                        {item.product.description.length > 0 && <p className="text-zinc-600 text-sm">{item.product.description}</p>}
                                        <div className="flex flex-col gap-2">
                                            <p className="text-zinc-500"><span className="text-zinc-500">L.</span> {item.product.price}</p>
                                            <div className="flex justify-between gap-5">
                                                <button
                                                    className="min-w-[35px] min-h-[35px] px-5 flex items-center justify-center rounded-full bg-orange-300 text-white"
                                                    onClick={() => removeProduct(item.product.id)}
                                                >
                                                    <BsTrash />
                                                </button>
                                                {shop.order.hasOwnProperty(item.product.id) &&
                                                    <div className="flex bg-gray-100 rounded-full w-[125px]">
                                                        <button
                                                            className="min-w-[35px] min-h-[35px] flex items-center justify-center rounded-full bg-orange-300 text-white"
                                                            onClick={() => decrementQuantity(item.product.id)}
                                                        >
                                                            <AiOutlineMinus />
                                                        </button>
                                                        <input className="bg-transparent w-full outline-none text-center text-zinc-500" value={shop.order[item.product.id].quantity} disabled />
                                                        <button
                                                            className="min-w-[35px] min-h-[35px] flex items-center justify-center rounded-full bg-orange-300 text-white"
                                                            onClick={() => incrementQuantity(item.product.id)}
                                                        >
                                                            <BsPlusLg />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                        ))}
                        <hr />
                        <div className="flex flex-col">
                            <div className="flex justify-between -mt-[13px]">
                                <span className="px-5 bg-white text-orange-300">Subtotal</span>
                                <span className="px-5 bg-white text-orange-300">{Object.values(shop.order).reduce((prev, crr) => prev + (crr.product.price * crr.quantity), 0)} {lang['currency']['abbreviation']}</span>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="flex justify-between -mt-[13px]">
                                <span className="px-5 bg-white text-orange-300">Envío</span>
                                <span className="px-5 bg-white text-orange-300">50 {lang['currency']['abbreviation']}</span>
                            </div>
                        </div>

                        <div className="flex flex-col">
                            <div className="flex justify-between -mt-[13px]">
                                <span className="px-5 bg-white text-orange-300">Total</span>
                                <span className="px-5 bg-white text-orange-300">{Object.values(shop.order).reduce((prev, crr) => prev + (crr.product.price * crr.quantity), 50)} {lang['currency']['abbreviation']}</span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <input className="px-5 py-3 rounded-full border-2 border-orange-300 bg-orange-50 text-orange-300 placeholder:text-orange-300" placeholder="Escribe tu dirección" onChange={handleChangeAddress} value={address} />
                        </div>

                        {address.length > 0 &&
                            <>
                                <p className="text-gray-500">Selecciona un método de pago</p>
                                <div className="flex gap-2">
                                    <button className={`flex-auto ${paymentMethod === 'CREDIT_CARD' ? 'bg-gray-900' : 'bg-gray-100'} rounded overflow-hidden p-2`} onClick={() => setPaymentMethod('CREDIT_CARD')}>
                                        <p className={`flex justify-center uppercase text-xs items-center gap-1 ${paymentMethod === 'CREDIT_CARD' ? 'text-white' : 'text-gray-500'}`}>
                                            <AiFillCreditCard className={paymentMethod === 'CREDIT_CARD' ? 'text-green-700' : 'text-gray-500'} /> {lang['es']['credit_card']}
                                        </p>
                                    </button>
                                    <button className={`flex-auto ${paymentMethod === 'CASH' ? 'bg-gray-900' : 'bg-gray-100'} rounded overflow-hidden p-2`} onClick={() => setPaymentMethod('CASH')}>
                                        <p className={`flex justify-center uppercase text-xs items-center gap-1 ${paymentMethod === 'CASH' ? 'text-white' : 'text-gray-500'}`}>
                                            <BsCashStack className={paymentMethod === 'CASH' ? 'text-green-700' : 'text-gray-500'} /> {lang['es']['cash']}
                                        </p>
                                    </button>
                                </div>


                                {paymentMethod === 'CREDIT_CARD' && <div className="flex flex-col gap-2">
                                    <Input type="text" name="titularName" placeholder="Nombre" onChange={handleCreditCardName} value={creditCard.titularName} />
                                    <Input type="number" name="creditCardNumber" placeholder="Número de la tarjeta de crédito" onChange={handleChangeCreditData} value={creditCard.creditCardNumber} />
                                    <div className="flex gap-5">
                                        <Input className="w-full" type="number" name="cvc" placeholder="Cvc" onChange={handleCvcCreditCard} value={creditCard.cvc} />
                                        <Input className="w-full" type="text" name="expiration" placeholder="Vencimiento" onChange={handleChangeCreditData} value={creditCard.expiration} />
                                    </div>
                                </div>}
                            </>
                        }
                        {address.length > 0 && paymentMethod && validateCreditCard() &&
                            <Button spinner={requesting} disabled={requesting} onClick={sendOrder}>{!requesting && 'Enviar pedido'}</Button>
                        }
                    </div>
                </div>
            </Modal>
            <div className="flex fixed bottom-5 left-0 w-full px-10">
                <button onClick={() => setCartOpen(true)} className="flex items-center justify-between bg-orange-300 text-white rounded-full px-5 w-full h-[55px] shadow-md outline-none">
                    <span>{Object.values(shop.order).reduce((prev, crr) => prev + (crr.product.price * crr.quantity), 0)} {lang['currency']['abbreviation']}</span>
                    Pagar
                </button>
            </div>
        </>
    )
}


export default OrderList