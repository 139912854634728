import { RiDoorClosedLine, RiDoorOpenLine, RiMap2Line, RiPriceTag3Line } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { deleteShop as deleteShopService, updateShopStatus } from "../../services/shops"
import { deleteShop as deleteShopAction, updateStatusShop as updateStatusShopAction } from "../../features/shopsSlice"
import toast from "react-hot-toast"
import { CLIENT_URL } from "../../config"

const Shop = (props) => {
    const token = useSelector(state => state.token)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        shop: {
            id,
            name,
            shopCategory: category,
            address,
            logo,
            closed
        },
        showOptions = false
    } = props

    const handleClickToDelete = () => {
        deleteShopService(token, id).then(res => {
            dispatch(deleteShopAction(id))
            toast.success(res.data.message)
        })
    }

    const handleStatus = () => {
        updateShopStatus(token, id).then(res => {
            dispatch(updateStatusShopAction(id))
            toast.success(res.data.message)
        })
    }

    // return (
    //     <div className="flex flex-col gap-5 cursor-pointer" onClick={() => navigate(`/shops/${id}`)}>
    //         <div className="flex items-center gap-5">
    //             <div className="bg-gray-200 w-[50px] h-[50px] rounded"></div>
    //             <div className="flex flex-col">
    //                 <p className="text-zinc-600 font-semibold">{name}</p>
    //                 <p className="flex gap-2 items-center text-zinc-600 text-sm">
    //                     <RiPriceTag3Line />
    //                     {category.name}
    //                 </p>
    //                 <p className="flex gap-2 items-center text-zinc-600 text-sm">
    //                     <RiMap2Line />
    //                     {address.address}
    //                 </p>
    //                 {/* {closed ?
    //                     <p className="flex gap-2 items-center text-red-600 text-sm">
    //                         <RiDoorClosedLine />
    //                         Cerrado
    //                     </p>
    //                     :
    //                     <p className="flex gap-2 items-center text-green-600 text-sm">
    //                         <RiDoorOpenLine />
    //                         Abierto
    //                     </p>
    //                 } */}
    //             </div>
    //         </div>
    //         {showOptions &&
    //             <>
    //                 <div className="flex gap-5">
    //                     {/* <Link className="text-blue-600 text-xs w-full px-5 py-2 text-center uppercase font-semibold bg-gray-50 rounded" to={`/shop/update/${id}`}>Editar</Link> */}
    //                     <button className="text-red-600 text-xs w-full px-5 py-2 text-center uppercase font-semibold bg-gray-50 rounded" onClick={handleClickToDelete}>Eliminar</button>
    //                 </div>
    //                 <div className="flex gap-5">
    //                     <button className={`${closed ? 'text-green-600' : 'text-red-600'} text-xs w-full px-5 py-2 text-center uppercase font-semibold bg-gray-50 rounded`} onClick={handleStatus}>
    //                         {closed ? 'Abrir' : 'Cerrar'}
    //                     </button>
    //                 </div>
    //             </>
    //         }
    //     </div>
    // )


    return (
        <Link className="" to={`/shops/${id}/products`}>
            <div className="flex flex-col w-[300px] mx-auto bg-[#FFAF00] rounded-3xl overflow-hidden">
                {/* <img className="object-cover max-h-[300px]" src="./shop.jpeg" alt="shop-logo" /> */}
                <div style={{ backgroundImage: `url(${logo})` }} className="flex flex-col max-h-[300px] h-[300px] p-3 bg-cover bg-no-repeat">
                    <span className={`self-end ml-auto font-light ${!closed ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}  px-5 py-1 rounded-lg`}>{closed ? 'Cerrado' : 'Abierto'}</span>
                </div>
                <div className="flex flex-col px-5 py-3">
                    <p className="text-white text-sm font-semibold">{name}</p>
                    <p className="text-white text-xs font-light">{address.address}</p>
                    {/* <p className="text-white text-sm font-light">{closed ? 'Cerrado' : 'Abierto'}</p> */}
                </div>
            </div>
        </Link>
    )
}

export default Shop