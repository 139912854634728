import { useState } from "react"
import { Toaster } from "react-hot-toast"
import { Outlet, useHref } from "react-router-dom"
import Navigation from "../../components/navigation"


const AppTemplate = () => {
    const location = useHref()

    console.log(location)
    console.log(location.split('/'))

    useState(() => {
        document.body.style.overflow = 'auto'
    }, [])

    return (
        <main className="flex flex-col gap-5 min-h-screen">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            {location.split('/')[1] !== 'shops' && <Navigation />}
            <Outlet />
        </main>
    )
}

export default AppTemplate