import { useState } from "react"
import toast, { Toaster } from "react-hot-toast"
import { Link } from "react-router-dom"
import { createUser } from "../../../services/users"

const Register = () => {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        areaCode: '',
        number: '',
    })

    const handleChange = ({ target }) => setForm({ ...form, [target.name]: target.value })
    const handleSubmit = (event) => {
        event.preventDefault()
        createUser(form).then(() => {
            toast.success("¡Te haz registrado exitosamente!")
        }).catch(error => {
            toast.error(error.response.data.message)
        })

    }

    return (
        <main className="flex gap-5 min-h-screen items-center bg-[#e2e2e2] p-5">
            <Toaster
                position="bottom-center"
                reverseOrder={false}
            />
            <div className="flex flex-col gap-5 bg-white rounded w-full p-5">
                <h1 className="text-zinc-600 uppercase font-semibold">Registrarse</h1>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-2">
                        <label className="text-zinc-600">Nombre</label>
                        <input
                            className="h-[50px] px-5 border border-gray-200 rounded"
                            name="firstName"
                            type="text" placeholder="Juan" value={form.firstName} onChange={handleChange} />
                    </div>

                    <div className="flex flex-col gap-2">
                        <label className="text-zinc-600">Apellido</label>
                        <input
                            className="h-[50px] px-5 border border-gray-200 rounded"
                            name="lastName"
                            type="text" placeholder="Gauna" value={form.lastName} onChange={handleChange} />
                    </div>


                    <div className="flex flex-col gap-2">
                        <label className="text-zinc-600">Correo electrónico</label>
                        <input
                            className="h-[50px] px-5 border border-gray-200 rounded"
                            name="email"
                            type="email" placeholder="banasupro@gmail.com" value={form.email} onChange={handleChange} />
                    </div>

                    <div className="flex gap-5">
                        <div className="flex flex-col gap-2">
                            <label className="text-zinc-600">Código de área</label>
                            <input
                                className="h-[50px] w-full px-5 border border-gray-200 rounded"
                                name="areaCode"
                                type="text" placeholder="3483" value={form.areaCode} onChange={handleChange} />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label className="text-zinc-600">Número</label>
                            <input
                                className="h-[50px] w-full px-5 border border-gray-200 rounded"
                                name="number"
                                type="text" placeholder="403020" value={form.number} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="flex flex-col gap-2">
                        <label className="text-zinc-600">Contraseña</label>
                        <input
                            className="h-[50px] px-5 border border-gray-200 rounded"
                            name="password"
                            type="password" placeholder="******************" value={form.password} onChange={handleChange} />
                    </div>

                    <button
                        className="bg-orange-500 h-[50px] rounded text-white uppercase text-sm font-semibold"
                    >Registrarse</button>
                </form>
                <p className="text-zinc-600 text-center">Ya tenés cuenta? <Link to="/login" className="font-semibold">Iniciá sesión</Link></p>
                <Link to="/" className="text-zinc-600 text-center font-semibold">Volver al inicio</Link>
            </div>
        </main>
    )
}

export default Register