import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteAddress } from "../../features/addressesSlice";

const Address = (props) => {
    const dispatch = useDispatch()
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const {
        address: {
            id,
            name,
            address,
            intersection,
            createdAt
        },
        selected,
        showDate = false,
        showOptions = false,
        showName = true,
        onClick
    } = props

    const handleClickToDelete = () => {
        dispatch(deleteAddress(id))
        toast.success("Dirección eliminada")
    }

    return (
        <div
            className={`flex flex-col gap-2 p-5 border border-gray-100 ${selected ? 'bg-gray-100 border-gray-300' : 'bg-white border-gray-100'} rounded transition-all duration-100`}
            onClick={onClick}
        >
            {showName &&
                <p className="text-zinc-600 text-sm font-semibold">{name}</p>}
            <p className="text-zinc-600 text-xs">
                {address}
                {intersection && ` y ${intersection}`}
            </p>
            {showDate &&
                <p className="text-zinc-600 text-xs">{new Date(createdAt).toLocaleDateString("es-AR", options)}</p>}

            {showOptions &&
                <div className="flex gap-2">
                    <Link className="text-blue-600 text-xs w-full px-5 py-2 text-center uppercase font-semibold bg-gray-50 rounded" to={`/address/${id}`}>Editar</Link>
                    <button className="text-red-600 text-xs w-full px-5 py-2 text-center uppercase font-semibold bg-gray-50 rounded" onClick={handleClickToDelete}>Eliminar</button>
                </div>
            }
        </div>
    )
}

export default Address