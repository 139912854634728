import axios from "axios";
import { API_URL } from "../config";

export const getCustomer = ({ customerToken }) => axios.get(`${API_URL}/customers`, {
    headers: {
        Authorization: `Bearer ${customerToken}`
    }
})

export const updateCustomer = ({ customerToken, fullName, phoneNumber }) => axios.patch(`${API_URL}/customers`, {
    fullName, phoneNumber
}, {
    headers: {
        Authorization: `Bearer ${customerToken}`
    }
})