import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import Button from "../../../../components/button"
import Card from "../../../../components/card"
import Input from "../../../../components/input"
import Section from "../../../../components/section"
import { updateAddress } from "../../../../features/addressesSlice"

const UpdateAddress = () => {
    const { id } = useParams()
    const { addresses } = useSelector(state => state)
    const [address, setAddress] = useState({
        name: '',
        address: ''
    })
    const [addressOriginal, setAddressOriginal] = useState({
        name: '',
        address: ''
    })
    const [error, setError] = useState(false)
    const dispatch = useDispatch()

    const handleChange = ({ target }) => setAddress({ ...address, [target.name]: target.value })

    const handleSubmit = (event) => {
        event.preventDefault()

        if (address.name === addressOriginal.name && address.address === addressOriginal.address) return
        if (address.name.length === 0 || address.address.length === 0) return

        dispatch(updateAddress({ addressId: id, name: address.name, address: address.address }))
        toast.success("Dirección actualizada")
    }

    useEffect(() => {
        const addressData = addresses.find(address => address.id === id)
        if (!addressData) setError(true)
        setAddress({
            name: addressData.name,
            address: addressData.address,
        })
        setAddressOriginal({
            name: addressData.name,
            address: addressData.address,
        })

    }, [addresses, id])


    return (
        <Section>
            <Card>
                {
                    !error ?
                        <>
                            <h1 className="text-lg text-zinc-600">Editar "{address.name}"</h1>
                            <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                                <div className="flex flex-col gap-2">
                                    <label>Nombre <span className="text-xs">*Obligatorio</span></label>
                                    <Input name="name" type="text" placeholder="Casa" value={address.name} onChange={handleChange} />
                                </div>
                                <div className="flex flex-col gap-2">
                                    <label>Dirección <span className="text-xs">*Obligatorio</span></label>
                                    <Input name="address" type="address" placeholder="Sargento Cabral 355" value={address.address} onChange={handleChange} />
                                </div>
                                {/* <div className="flex flex-col gap-2">
                                    <label>Intersección</label>
                                    <Input name="intersection" type="text" placeholder="Bv belgrano" value={address.intersection} onChange={handleChange} />
                                </div> */}
                                <Button>Editar</Button>
                                <Link className="text-zinc-600 font-semibold text-center" to="/user/addresses">Volver</Link>
                            </form>
                        </>
                        :
                        <p className="text-gray-500">Ha ocurrido un error encontrando la dirección</p>
                }
            </Card>
        </Section>
    )
}

export default UpdateAddress