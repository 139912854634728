import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import Address from "../../../components/address"
import Button from "../../../components/button"
import Card from "../../../components/card"
import Category from "../../../components/category"
import Input from "../../../components/input"
import Label from "../../../components/label"
import Section from "../../../components/section"
import { setData } from "../../../features/userSlice"
import { getShopCategories } from "../../../services/categories"
import { createShop } from "../../../services/shops"
import { getUser } from "../../../services/users"

const CreateShop = () => {
    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const initForm = {
        name: '',
        categoryId: null,
        addressId: null
    }
    const [categories, setCategories] = useState([])
    const [form, setFormData] = useState(initForm)

    const handleChange = ({ target }) => setFormData({ ...form, [target.name]: target.value })
    const selectCategory = categoryId => setFormData({ ...form, categoryId: categoryId === form.categoryId ? null : categoryId })
    const selectAddress = addressId => setFormData({ ...form, addressId: addressId === form.addressId ? null : addressId })

    const handleSubmit = event => {
        event.preventDefault()

        createShop(token, form)
            .then(res => {
                toast.success(res.data.message)
                setFormData(initForm)
            })
            .catch(({ response: res }) => toast.error(res.data.message))
    }

    useEffect(() => {
        getUser(token).then((user) => dispatch(setData(user.data.payload)))
        getShopCategories().then((categories) => setCategories(categories.data.payload))
    }, [token, dispatch])
    return (
        <Section>
            <Card>
                <p>Crear tienda</p>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <Label text="Nombre">
                        <Input type="text" name="name" placeholder="El plaza" onChange={handleChange} />
                    </Label>
                    <Label text="Categorías">
                        {categories.map((category) => <Category key={category.id} category={category} selected={form.categoryId === category.id} onClick={() => selectCategory(category.id)} />)}
                    </Label>
                    <Label text="Direcciones">
                        {user.addresses.map((address) => <Address key={address.id} address={address} selected={form.addressId === address.id} onClick={() => selectAddress(address.id)} />)}
                    </Label>
                    <Button>Crear</Button>
                </form>
            </Card>
        </Section>
    )
}

export default CreateShop