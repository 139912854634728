const Input = (props) => {
    const style = "h-[50px] px-5 border border-gray-200 rounded-full"
    return (
        <input
            {...props}
            className={style.concat(' ', props.className)}
        />
    )
}

export default Input