import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userId: null,
    shopCategory: null,
    address: null,
    name: "",
    shopSections: [],
    products: [],
    closed: false,
    order: {}
}

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setShopData: (state, { payload }) => {
            state.userId = payload.userId
            state.shopCategory = payload.shopCategory
            state.name = payload.name
            state.address = payload.address
            state.name = payload.name
            state.closed = payload.closed
        },
        setSections: (state, { payload: sections }) => {
            state.shopSections = sections
        },
        addProductToShopOrder: (state, { payload }) => {
            state.order[payload.product.id] = payload
        },
        incrementProductQuantity: (state, { payload: { productId } }) => {
            state.order[productId].quantity += 1
        },
        decrementProductQuantity: (state, { payload: { productId } }) => {
            state.order[productId].quantity -= 1
        },
        removeProductToShopOrder: (state, { payload: { productId } }) => {
            delete state.order[productId]
        },
        removeShopSection: (state, { payload: { sectionId } }) => {
            const newShopSections = state.shopSections.filter(shopSection => shopSection.id !== sectionId)
            state.shopSections = newShopSections
        },
        setShopProducts: (state, { payload: products }) => {
            state.products = products
        },
        editProduct: (state, { payload: { id, name, description, price } }) => {
            const newProducts = state.products.map(product => {
                if (product.id === id) {
                    product.name = name
                    product.description = description
                    product.price = price
                    return product
                }
                return product
            })
            state.products = newProducts
        },
        toggleSoldOutProduct: (state, { payload: { id } }) => {
            const newProducts = state.products.map(product => {
                if (product.id === id) {
                    product.soldOut = !product.soldOut
                    return product
                }
                return product
            })
            state.products = newProducts
        },
        deleteShopProduct: (state, { payload: { id } }) => {
            const newProducts = state.products.filter(product => product.id !== id)
            state.products = newProducts
        },
        resetOrder: (state) => {
            state.order = {}
        }
    }

})
export const {
    setShopData,
    setSections,
    addProductToShopOrder,
    incrementProductQuantity,
    decrementProductQuantity,
    removeProductToShopOrder,
    removeShopSection,
    setShopProducts,
    editProduct,
    toggleSoldOutProduct,
    deleteShopProduct,
    resetOrder
} = shopSlice.actions
export default shopSlice.reducer