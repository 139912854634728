const TextArea = (props) => {
    const style = "h-[50px] p-5 border border-gray-200 rounded resize-none h-[80px]"
    return (
        <textarea
            {...props}
            className={style.concat(' ', props.className)}
        ></textarea>
    )
}

export default TextArea