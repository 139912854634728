import { Link, useParams } from "react-router-dom"

const ShopSection = ({ section: { id, name, products }, initialState = false }) => {
    const { id: shopId } = useParams()
    return (
        <Link to={`/shops/${shopId}/sections/${id}/products`}>
            <div className="min-w-[150px] min-h-[50px] p-5 rounded-2xl flex flex-col bg-orange-50">
                <p className="text-orange-300">{name}</p>
            </div>
        </Link >
    )
}

export default ShopSection