import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Product from "../../../../components/product"
import { getShopProducts } from "../../../../services/shops"
import { setShopProducts } from "../../../../features/shopSlice"
import Input from "../../../../components/input"
import { RiSearchLine } from "react-icons/ri"

const ShopProducts = () => {
    const { id: shopId } = useParams()

    const { token, shop } = useSelector(state => state)

    const dispatch = useDispatch()

    useEffect(() => {
        getShopProducts({ token, shopId }).then(res => {
            dispatch(setShopProducts(res.data.payload))
        })
    }, [shopId, token])

    return (
        <section className="flex flex-col gap-5 pb-16">
            {shop.products.map(product => <Product key={product.id} product={product} />)}
        </section>
    )
}

export default ShopProducts