import { useEffect, useRef, useState } from "react"
import { RiAddLine, RiBikeLine, RiHome2Line, RiLogoutBoxLine, RiMap2Line, RiMenuLine, RiShoppingBasket2Line, RiStore2Line, RiUser2Line } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { deleteToken } from "../../features/tokenSlice"
import { resetCustomer } from "../../features/customerSlice"

const ItemSidebar = ({ to, children, icon, onClick }) => {
    return (
        <Link
            className="flex items-center gap-3 p-3 bg-white hover:bg-gray-200 text-zinc-600 rounded-full"
            to={to}
            onClick={onClick}
        >
            {icon}
            {children}
        </Link>
    )
}

const Menu = ({ open, set }) => {
    const ref = useRef()
    const children = useRef()

    const { token, customer, user } = useSelector(state => state)

    const dispatch = useDispatch()

    const logout = () => {
        dispatch(resetCustomer())
    }

    const handleChangePage = () => {
        set(false)
        document.body.style.overflow = 'auto'
    }

    useEffect(() => {
        ref.current.addEventListener('click', (event) => {
            if (!children.current.contains(event.target)) {
                set(false)
                document.body.style.overflow = 'auto'
            }
        })
    }, [set])
    
    return (
        <div ref={ref} className={`${open ? 'visible bg-[#0000006a] opacity-100' : 'invisible bg-transparent opacity-0'} z-10 fixed top-0 left-0 shadow-md w-screen h-full transition-all duration-150`}>
            <div ref={children} className={`flex flex-col ${open ? 'translate-x-0' : '-translate-x-10'} bg-white w-[300px] h-full max-h-full shadow-md transition-all duration-100 overflow-y-auto`}>
                <div className="flex flex-col gap-5 p-5">
                    {token ?
                        <div className="flex flex-col p-3">
                            <p className="capitalize font-semibold text-zinc-600">{user.firstName} {user.lastName}</p>
                            <p className="text-sm text-zinc-600">{user.email}</p>
                        </div>

                        :
                        <div className="flex flex-col p-3">
                            <p className="capitalize font-semibold text-zinc-600">{customer.fullName}</p>
                            <p className="text-sm text-zinc-600">{customer.phoneNumber}</p>
                        </div>
                    }
                    <ItemSidebar to="/home" icon={<RiHome2Line />} onClick={handleChangePage}>Página principal</ItemSidebar>
                    <ItemSidebar to="/orders" icon={<RiShoppingBasket2Line />} onClick={handleChangePage}>Mis Pedidos</ItemSidebar>
                    <ItemSidebar to="/user/data" icon={<RiUser2Line />} onClick={handleChangePage}>Datos personales</ItemSidebar>
                </div>
                <div className="flex flex-col gap-5 p-5 mt-auto">
                    <ItemSidebar icon={<RiLogoutBoxLine />} onClick={logout}>Cerrar sesión</ItemSidebar>
                </div>
            </div>
        </div>
    )
}

const Navigation = () => {
    const [menu, setMenu] = useState(false)

    const openMenu = () => {
        setMenu(!menu)
        document.body.style.overflow = 'hidden'
    }

    return (
        <div className="sticky top-0 flex w-[300px] bg-white mx-auto py-10 items-center justify-between">
            <img className="w-[100px]" src="/logo1.png" alt="app-logo" />
            {/* <p className="text-zinc-500 text-3xl">Banasupro</p> */}
            <button className={`flex flex-col gap-2 text-zinc-500 text-xl`} onClick={openMenu}>
                {/* <RiMenuLine /> */}
                <div className={`border-[1px] rounded-lg border-black w-[30px] ${menu ? 'translate-x translate-y-2 rotate-45' : 'rotate-0'} duration-75`}></div>
                <div className={`border-[1px] rounded-lg border-black ${menu ? '-rotate-45 w-[30px]' : 'rotate-0 w-[20px]'} duration-75 self-end`}></div>
                <div className={`border-[1px] rounded-lg border-black ${menu ? 'invisible w-[0px]' : 'visible w-[10px]'} duration-75 self-end`}></div>
            </button>
            <Menu open={menu} set={setMenu} />
        </div >
    )
}

export default Navigation