import { combineReducers } from "@reduxjs/toolkit"
import userReducer from '../features/userSlice'
import tokenReducer from '../features/tokenSlice'
import shopsReducers from "../features/shopsSlice"
import shopReducer from "../features/shopSlice"
import shopOrdersReducer from "../features/shopOrdersSlice"
import customerReducer from "../features/customerSlice"
import addressesReducers from "../features/addressesSlice"

const reducers = combineReducers({
    user: userReducer,
    token: tokenReducer,
    shops: shopsReducers,
    shop: shopReducer,
    shopOrders: shopOrdersReducer,
    customer: customerReducer,
    addresses: addressesReducers
})

export default reducers