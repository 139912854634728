import axios from "axios";
import { API_URL } from "../config";

export const createSection = ({ token, shopId, sectionName }) => axios.post(`${API_URL}/shops/${shopId}/sections`,
    {
        name: sectionName
    },
    {
        headers: {
            authorization: `Bearer ${token}`
        }
    }
)

export const getSection = ({ token, sectionId }) => axios.get(`${API_URL}/shops/sections/${sectionId}`, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const updateSectionName = ({ token, sectionId, shopId, sectionName }) => axios.patch(`${API_URL}/shops/sections/${sectionId}`, {
    shopId, sectionName
}, {
    headers: {
        authorization: `Bearer ${token}`
    }
}
)

export const deleteSection = ({ token, sectionId, shopId }) => axios.delete(`${API_URL}/shops/sections/${sectionId}/${shopId}`, {
    headers: {
        authorization: `Bearer ${token}`
    }
}
)