import { useState } from "react"
import toast, { Toaster } from "react-hot-toast"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { setToken } from "../../../features/tokenSlice"
import { login } from "../../../services/auth"
import { setCustomer } from "../../../features/customerSlice"
import axios from "axios"
import { API_URL, CLIENT_URL } from "../../../config"
import Button from "../../../components/button"
import Input from "../../../components/input"
import theme from "../../../theme"

const Login = () => {
    const [form, setForm] = useState({
        fullName: '',
        phoneNumber: ''
    })

    const [requesting, setRequesting] = useState(false)

    const dispatch = useDispatch()

    const handleChange = ({ target }) => {
        // console.log(/^[a-zA-Z\u00C0-\u017F]+$/.test(target.value))
        if (target.value.length === 0) {
            setForm({ ...form, [target.name]: '' })
            return
        }
        if (!/^[a-zA-Z\u00C0-\u017F\s]+$/.test(target.value)) return
        setForm({ ...form, [target.name]: target.value })
    }

    const handlePhoneNumberChange = ({ target }) => {
        if (target.value.length > 8) return

        setForm({ ...form, [target.name]: target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setRequesting(true)
        if (form.fullName.length === 0 || form.phoneNumber.length === 0) {
            toast.error('Completa el formulario.')
            setRequesting(false)
        } else {
            axios.post(`${API_URL}/customers/validate`, form).then((res) => {
                dispatch(setCustomer(res.data.payload.customer))
                setRequesting(false)
            }).catch(() => {
                setRequesting(false)
                toast.error('Ha ocurrido un error')
            })
        }
    }

    return (
        <main className={`flex gap-10 min-h-screen bg-orange-50 bg-cover bg-center bg-no-repeat`} style={{ backgroundImage: `url(${CLIENT_URL}/shop.jpeg)` }}>
            <div className="flex flex-auto justify-center items-center min-h-full bg-[#0000009f]">
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                />
                <div className="flex max-w-[350px] flex-col gap-5 bg-white mx-5 rounded-3xl shadow-2xl w-full overflow-hidden">
                    <div className={`${theme.bg}`}>
                        <div className="flex h-full px-5 py-10">
                            <img className="mx-auto w-[120px]" src="/logo1_bg_white.png" alt="page-logo" />
                        </div>
                    </div>
                    <p className="text-gray-500 px-3 text-center text-sm">Completa con tus datos para poder ver el catálogo de nuestras sucursales.</p>
                    <form className="flex flex-col p-5 gap-5" onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-2">
                            {/* <label className="text-zinc-600">Nombre completo</label>
                        <input
                            className="h-[50px] px-5 border border-gray-200 rounded"
                            name="fullName"
                            type="text" onChange={handleChange} /> */}
                            <Input type="text" name="fullName" placeholder="Nombre completo" onChange={handleChange} value={form.fullName} />
                        </div>


                        <div className="flex flex-col gap-2">
                            {/* <label className="text-zinc-600">Número de teléfono</label> */}
                            {/* <input
                            className="h-[50px] px-5 border border-gray-200 rounded"
                            name="phoneNumber"
                            type="text" onChange={handleChange} /> */}

                            <Input type="number" name="phoneNumber" placeholder="Número de teléfono" onChange={handlePhoneNumberChange} value={form.phoneNumber} />
                        </div>

                        <Button spinner={requesting} disabled={requesting}>Entrar</Button>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Login