import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import Address from "../../../components/address"
import Button from "../../../components/button"
import Card from "../../../components/card"
import Input from "../../../components/input"
import Section from "../../../components/section"
import { v4 } from 'uuid'
import { addAddress } from "../../../features/addressesSlice"

const Addresses = () => {
    const initState = {
        name: '',
        address: '',
        intersection: ''
    }

    const { addresses } = useSelector(state => state)
    const [address, setAddress] = useState(initState)
    const dispacth = useDispatch()

    const handleChange = ({ target }) => setAddress({ ...address, [target.name]: target.value })

    const handleSubmit = (event) => {
        event.preventDefault()
        if (address.name.length === 0 || address.address.length === 0) return toast.error('Completa el formulario con un nombre para la dirección y la calle con su número.')

        dispacth(addAddress({ ...address, id: v4(), createdAt: Date.now(), updatedAt: Date.now() }))
        setAddress(initState)
    }

    return (
        <Section>
            <Card>
                <h1 className="text-zinc-600 text-lg">Dirección nueva</h1>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-2">
                        <label>Nombre para la dirección <span className="text-xs">*Obligatorio</span></label>
                        <Input name="name" type="text" placeholder="Casa" value={address.name} onChange={handleChange} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label>Dirección <span className="text-xs">*Obligatorio</span></label>
                        <Input name="address" type="address" placeholder="Sargento Cabral 355" value={address.address} onChange={handleChange} />
                    </div>
                    {/* <div className="flex flex-col gap-2">
                        <label>Intersección</label>
                        <Input name="intersection" type="text" placeholder="Bv belgrano" value={address.intersection} onChange={handleChange} />
                    </div> */}
                    <Button>Crear</Button>
                </form>
            </Card>
            <Card>
                <h1 className="text-lg text-zinc-600">Lista de direcciones</h1>
                {addresses.length === 0 ?
                    <p>Tu usuario no tiene direcciones creadas.</p>
                    :
                    addresses.map((address) => (
                        <Address key={address.id} address={address} showDate={true} showOptions={true} />
                    ))
                }
            </Card>
        </Section>
    )
}

export default Addresses