import Section from "../../../components/section"
import Card from "../../../components/card"
import Input from "../../../components/input"
import Button from "../../../components/button"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getCustomer, updateCustomer } from "../../../services/customer"
import { setCustomer } from "../../../features/customerSlice"
import toast from "react-hot-toast"

const Profile = () => {
    const { customer } = useSelector(state => state)
    const [data, setCustomerData] = useState({
        fullName: '',
        phoneNumber: ''
    })

    const dispatch = useDispatch()


    const handleInputChange = ({ target: { name, value } }) => setCustomerData({ ...data, [name]: value })


    const handleUpdateCustomer = (event) => {
        event.preventDefault()
        if (data.fullName === customer.fullName && data.phoneNumber === customer.phoneNumber) return
        if (data.fullName.length === 0 && data.phoneNumber.length === 0) return
        
        updateCustomer({ customerToken: customer.token, fullName: data.fullName, phoneNumber: data.phoneNumber }).then(() => {
            dispatch(setCustomer({ ...customer, fullName: data.fullName, phoneNumber: data.phoneNumber }))
            toast.success('Datos actualizados')
        })
    }


    useEffect(() => {
        getCustomer({ customerToken: customer.token }).then((res) => {
            setCustomerData({
                fullName: res.data.payload.customer.fullName,
                phoneNumber: res.data.payload.customer.phoneNumber,
            })
        })
    }, [dispatch])

    return (
        <Section>
            <Card>
                <h1 className="text-zinc-600 text-lg">Datos de usuario</h1>
                <form className="flex flex-col gap-5" onSubmit={handleUpdateCustomer}>
                    <div className="flex flex-col gap-2">
                        <label className="text-zinc-600">Nombre completo <span className="text-xs">*Obligatorio</span></label>
                        <Input name="fullName" type="text" placeholder="Juan" value={data.fullName} onChange={handleInputChange} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-zinc-600">Número de telefono <span className="text-xs">*Obligatorio</span></label>
                        <Input name="phoneNumber" type="number" placeholder="Gauna" value={data.phoneNumber} onChange={handleInputChange} />
                    </div>
                    <Button>Actualizar</Button>
                </form>
            </Card>
        </Section>
    )
}

export default Profile