import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const shopsSlice = createSlice({
    name: 'shops',
    initialState,
    reducers: {
        setShopList: (state, { payload }) => payload,
        addShop: (state, { payload }) => state.push(payload),
        deleteShop: (state, { payload }) => {
            const newList = state.filter(shop => shop.id !== payload)
            return newList
        },
        updateStatusShop: (state, { payload: shopId }) => {
            state.map(shop => {
                if (shop.id === shopId) {
                    shop.closed = !shop.closed
                }
                return shop
            })
        }
    }

})
export const { setShopList, addShop, deleteShop, updateStatusShop } = shopsSlice.actions
export default shopsSlice.reducer