import { useEffect, useRef, useState } from "react"
import { RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri"

const Dropdown = ({ initialState = false, name, children }) => {
    const content = useRef()
    const [open, setOpen] = useState(true)
    const [contentHeight, setContentHeight] = useState(initialState)

    useEffect(() => {
        if (content.current) {
            setContentHeight(content.current.clientHeight)
            setOpen(initialState)
        }
    }, [])

    return (
        <div className="bg-white p-5 flex flex-col rounded">
            <button className="flex gap-2 items-center" onClick={() => setOpen(!open)}>
                {name} {open ? <RiArrowDropDownFill /> : <RiArrowDropRightFill />}
            </button>
            <div
                ref={content}
                style={{ height: open ? contentHeight : 0, overflow: 'auto' }}
                className={`duration-100`}>
                {
                    children
                }
            </div>
        </div>

    )
}

export default Dropdown