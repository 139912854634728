const Category = (props) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const {
        category: {
            name,
            createdAt
        },
        selected,
        showDate = undefined,
        onClick
    } = props
    return (
        <div
            className={`flex flex-col gap-2 px-5 py-3 border ${selected ? 'bg-gray-100 border-gray-300' : 'bg-white border-gray-100'} rounded transition-all duration-100`}
            onClick={onClick}
        >
            <p className="text-zinc-600 text-sm font-semibold">{name}</p>
            {showDate &&
                <p className="text-zinc-600 text-xs">{new Date(createdAt).toLocaleDateString("es-AR", options)}</p>}
        </div>
    )
}

export default Category